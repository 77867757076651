import { useAuth0 } from '@auth0/auth0-react';
import { AuthenticationContext } from '@xpanse/authz-service-client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { ErrorAlert, Iconify } from '@/components';
import { CustomPopover, usePopover } from '@/components/custom-popover-new';
import Label from '@/components/label';
import { useAppContext } from '@/context/AppContext';
import RoleGuard from '@/context/auth/RoleGuard';
import { CustomerType } from '@/enums';
import { useGetAuthenticationContexts, useUserProfile } from '@/hooks/useUsers';

const AuthContextMenuButton = ({ authContexts }: { authContexts: AuthenticationContext[] }) => {
  const popover = usePopover();

  const { getAccessTokenSilently } = useAuth0();

  const { isLoadingAuthContext } = useAppContext();

  const navigate = useNavigate();

  const { organizationCode } = useUserProfile();

  const currentAuthContext = authContexts.find(
    (authContext) => authContext.organizationCode === organizationCode
  );

  const handleChangeAuthContext = useCallback(
    async (authContext: AuthenticationContext) => {
      isLoadingAuthContext.onTrue();
      popover.onClose();

      await getAccessTokenSilently({
        authorizationParams: { scope: `refresh ${authContext.scope}` },
        detailedResponse: true,
        cacheMode: 'off',
      });

      isLoadingAuthContext.onFalse();

      navigate('/');
      window.location.reload();
    },
    [getAccessTokenSilently, navigate, popover, isLoadingAuthContext]
  );

  if (authContexts.length === 1) {
    return null;
  }

  return (
    <>
      <ButtonBase
        disableRipple
        onClick={popover.onOpen}
        sx={{
          py: 0.5,
          gap: 1,
        }}
      >
        <Box
          component="span"
          sx={{
            typography: 'subtitle2',
          }}
        >
          {currentAuthContext?.organizationName}
        </Box>

        <Label
          color="primary"
          sx={{
            height: 22,
          }}
        >
          {currentAuthContext?.organizationClientCode}
        </Label>

        <Iconify width={16} icon="carbon:chevron-sort" sx={{ color: 'text.disabled' }} />
      </ButtonBase>
      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{
          arrow: { placement: 'top-left' },
          paper: {
            sx: {
              // TODO: Breaks arrow positioning, set arrow.hide to true if keeping
              overflow: 'scroll',
            },
          },
        }}
      >
        <MenuList sx={{ flexGrow: 1 }}>
          {authContexts.map((authContext) => (
            <MenuItem
              key={authContext.scope}
              selected={authContext.scope === currentAuthContext?.scope}
              onClick={() => handleChangeAuthContext(authContext)}
              sx={{ height: 48 }}
            >
              <Box component="span" sx={{ flexGrow: 1 }}>
                {authContext.organizationName}
              </Box>

              <Label color="primary">{authContext.organizationClientCode}</Label>
            </MenuItem>
          ))}
        </MenuList>
      </CustomPopover>
    </>
  );
};

export default function AuthContextMenu() {
  const {
    data: authContexts,
    error,
    isLoading: isFetchingAuthContexts,
    isSuccess,
  } = useGetAuthenticationContexts();
  const { user } = useAuth0();

  const isLoading = isFetchingAuthContexts || !user;

  return (
    <RoleGuard role={CustomerType.lender}>
      {error && <ErrorAlert error={error} />}
      {isLoading && <CircularProgress color="inherit" size="24px" />}
      {isSuccess && user && <AuthContextMenuButton authContexts={authContexts} />}
    </RoleGuard>
  );
}
