/* eslint-disable @typescript-eslint/no-redeclare */

export const JsonFormMode = {
  READ_ONLY: 'read-only',
};

export type JsonFormMode = (typeof JsonFormMode)[keyof typeof JsonFormMode];

export const CustomerType = {
  admin: 'ADMIN',
  lender: 'LENDER',
  vendor: 'VENDOR',
};

export type CustomerType = (typeof CustomerType)[keyof typeof CustomerType];
