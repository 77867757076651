import { useAuth0 } from '@auth0/auth0-react';
import {
  BaseAPI,
  Configuration,
  IdentityManagementApi,
  OrganizationMembershipManagementApi,
  UsersApi,
} from '@xpanse/authz-service-client';

import fetchOverride from './fetch-override';

const useApiConfig = () => {
  const { user = {} } = useAuth0();
  const tenantAlias = user?.['https://xpanse.com/claims/auth/context']?.tenantAlias;

  return new Configuration({
    basePath:
      import.meta.env.MODE === 'local-services'
        ? `/authz-service/api/${tenantAlias}`
        : `${import.meta.env.VITE_AUTHZ_SERVICE_BASE_PATH}/${tenantAlias}`,
    fetchApi: fetchOverride,
  });
};

const useWithPreMiddleWare = <T extends BaseAPI>(api: T) => {
  const { getIdTokenClaims } = useAuth0();
  return api.withPreMiddleware<T>(async (context) => {
    const headers = new Headers(context.init.headers);
    const tokenClaims = await getIdTokenClaims();
    const token = tokenClaims?.__raw;

    headers.set('Authorization', `Bearer ${token}`);
    context.init.headers = headers;

    return context;
  });
};

export const useUsersApi = () => {
  const config = useApiConfig();
  return useWithPreMiddleWare(new UsersApi(config));
};

export const useIdentityManagementApi = () => {
  const config = useApiConfig();
  return useWithPreMiddleWare(new IdentityManagementApi(config));
};

export const useOrganizationMembershipManagementApi = () => {
  const config = useApiConfig();
  return useWithPreMiddleWare(new OrganizationMembershipManagementApi(config));
};
