import { createContext, useContext, useMemo } from 'react';

import { useBoolean } from '@/hooks/useBoolean';

interface IAppContext {
  isLoadingAuthContext: ReturnType<typeof useBoolean>;
}

export const AppContext = createContext<IAppContext | null>(null);

export const useAppContext = () => {
  const ctx = useContext(AppContext);
  if (!ctx) throw new Error('Context must be used inside a provider');
  return ctx;
};

export const ContextProvider = ({ children }: { children: React.ReactNode }) => {
  const isLoadingAuthContext = useBoolean();

  const contextValue = useMemo(
    () => ({
      isLoadingAuthContext,
    }),
    [isLoadingAuthContext]
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
