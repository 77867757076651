import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, useRoutes } from 'react-router';
import 'simplebar-react/dist/simplebar.min.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { SnackbarProvider } from '@/components/snackbar/';
import { ContextProvider } from '@/context/AppContext';
import { SettingsProvider } from '@/context/settings';
import ThemeProvider from '@/theme';

import { AppRoutes } from './routes';

const queryClient = new QueryClient();

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

const Router = () => {
  const routes = useRoutes(AppRoutes);
  return routes;
};

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light',
              themeLayout: 'vertical',
            }}
          >
            <ThemeProvider>
              <SnackbarProvider>
                <ContextProvider>
                  <Router />
                </ContextProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </HelmetProvider>
);
