import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AdminCreateIdentityRequest,
  AdminCreateOrganizationMembershipRequest,
  AdminDisableOrganizationMembershipAcrossAllIdentitiesRequest,
  AdminDisableOrganizationMembershipRequest,
  AdminEnableOrganizationMembershipAcrossAllIdentitiesRequest,
  AdminEnableOrganizationMembershipRequest,
  AdminGetIdentityDetailsRequest,
  AdminResendInvitationEmailRequest,
  AdminSearchOrganizationMembershipsRequest,
  AdminUpdateIdentityRequest,
  IdentityDetails,
  PersistedIdentityDetails,
  PersistedOrganizationMembershipDetails,
} from '@xpanse/authz-service-client';

import {
  useIdentityManagementApi,
  useOrganizationMembershipManagementApi,
  useUsersApi,
} from '@/api/auth-z';
import { IUserProfile } from '@/context/auth/types';
import { useErrorNotification } from '@/hooks/useErrorNotification';

const queryKey = (searchUsersRequest: AdminSearchOrganizationMembershipsRequest) => [
  'users',
  searchUsersRequest,
];

export const useUserProfile = (): IUserProfile => {
  const { user } = useAuth0();

  return user && user['https://xpanse.com/claims/auth/context'];
};

export const useSearchUsers = (searchUsersRequest: AdminSearchOrganizationMembershipsRequest) => {
  const orgMembersApi = useOrganizationMembershipManagementApi();
  return useQuery({
    queryKey: queryKey(searchUsersRequest),
    queryFn: () => orgMembersApi.adminSearchOrganizationMemberships(searchUsersRequest),
  });
};

export const useSendUserEmail = () => {
  const identityManagementApi = useIdentityManagementApi();
  const { reportError } = useErrorNotification();
  return useMutation<void, Error, AdminResendInvitationEmailRequest>({
    mutationFn: ({ identityCode }) =>
      identityManagementApi.adminResendInvitationEmail({ identityCode }),
    onError: (error) => reportError(error),
  });
};

export const useDisableUser = () => {
  const orgMembersApi = useOrganizationMembershipManagementApi();
  const queryClient = useQueryClient();
  const { reportError } = useErrorNotification();
  return useMutation<
    PersistedOrganizationMembershipDetails,
    Error,
    AdminDisableOrganizationMembershipRequest
  >({
    mutationFn: ({ organizationCode, identityCode }) =>
      orgMembersApi.adminDisableOrganizationMembership({
        identityCode,
        organizationCode,
      }),
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries({
        queryKey: queryKey({ organizationCode: variables.organizationCode }),
      }),
    onError: (error) => reportError(error),
  });
};

export const useDisableAllUsers = () => {
  const orgMembersApi = useOrganizationMembershipManagementApi();
  const queryClient = useQueryClient();
  const { reportError } = useErrorNotification();
  return useMutation<
    PersistedOrganizationMembershipDetails[],
    Error,
    AdminDisableOrganizationMembershipAcrossAllIdentitiesRequest
  >({
    mutationFn: ({ organizationCode }) =>
      orgMembersApi.adminDisableOrganizationMembershipAcrossAllIdentities({ organizationCode }),
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries({
        queryKey: queryKey({ organizationCode: variables.organizationCode }),
      }),
    onError: (error) => reportError(error),
  });
};

export const useEnableUser = () => {
  const orgMembersApi = useOrganizationMembershipManagementApi();
  const queryClient = useQueryClient();
  const { reportError } = useErrorNotification();
  return useMutation<
    PersistedOrganizationMembershipDetails,
    Error,
    AdminEnableOrganizationMembershipRequest
  >({
    mutationFn: ({ organizationCode, identityCode }) =>
      orgMembersApi.adminEnableOrganizationMembership({
        identityCode,
        organizationCode,
      }),
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries({
        queryKey: queryKey({ organizationCode: variables.organizationCode }),
      }),
    onError: (error) => reportError(error),
  });
};

export const useEnableAllUsers = () => {
  const orgMembersApi = useOrganizationMembershipManagementApi();
  const queryClient = useQueryClient();
  const { reportError } = useErrorNotification();
  return useMutation<
    PersistedOrganizationMembershipDetails[],
    Error,
    AdminEnableOrganizationMembershipAcrossAllIdentitiesRequest
  >({
    mutationFn: ({ organizationCode }) =>
      orgMembersApi.adminDisableOrganizationMembershipAcrossAllIdentities({ organizationCode }),
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries({
        queryKey: queryKey({ organizationCode: variables.organizationCode }),
      }),
    onError: (error) => reportError(error),
  });
};

export const useGetAuthenticationContexts = () => {
  const usersApi = useUsersApi();
  return useQuery({
    queryKey: ['auth-contexts'],
    queryFn: () => usersApi.getAuthenticationContexts(),
  });
};

const useCreateIdentity = () => {
  const identityManagementApi = useIdentityManagementApi();
  const { reportError } = useErrorNotification();
  return useMutation<PersistedIdentityDetails, Error, AdminCreateIdentityRequest>({
    mutationFn: ({ identityDetails, sendInvitationEmail }) =>
      identityManagementApi.adminCreateIdentity({ identityDetails, sendInvitationEmail }),
    onError: (error) => reportError(error),
  });
};

const useMutateOrgMembership = () => {
  const orgMembersApi = useOrganizationMembershipManagementApi();
  const queryClient = useQueryClient();
  const { reportError } = useErrorNotification();
  return useMutation<
    PersistedOrganizationMembershipDetails,
    Error,
    AdminCreateOrganizationMembershipRequest
  >({
    mutationFn: (user) => orgMembersApi.adminCreateOrganizationMembership(user),
    onSuccess: ({ organizationCode }) =>
      queryClient.invalidateQueries({ queryKey: queryKey({ organizationCode }) }),
    onError: (error) => reportError(error),
  });
};

interface ICreateUserValues extends IdentityDetails {
  roles: string[];
}

export const useCreateUser = () => {
  const { isPending: isCreatingIdentity, mutateAsync: createIdentity } = useCreateIdentity();
  const { isPending: isCreatingOrgMembership, mutate: createOrgMembership } =
    useMutateOrgMembership();

  return {
    isCreatingUser: isCreatingIdentity || isCreatingOrgMembership,
    createUser: async ({
      identityDetails,
      organizationCode,
      sendInvitationEmail,
    }: {
      identityDetails: ICreateUserValues;
      organizationCode: string;
      sendInvitationEmail: boolean;
    }) => {
      const { active, email, firstName, lastName, phone, roles } = identityDetails;
      const identity = await createIdentity({
        identityDetails: { active, email, firstName, lastName, phone },
        sendInvitationEmail,
      });
      return createOrgMembership({
        identityCode: identity.code,
        identityMembershipDetails: { active, roles: new Set(roles) },
        organizationCode,
      });
    },
  };
};

export const useCreateUsers = () => {
  const { createUser, isCreatingUser } = useCreateUser();

  const createUsers = async ({
    identities,
    organizationCode,
    sendInvitationEmail,
  }: {
    identities: ICreateUserValues[];
    organizationCode: string;
    sendInvitationEmail: boolean;
  }) =>
    Promise.all(
      identities.map(async (identityDetails) => {
        try {
          await createUser({ identityDetails, organizationCode, sendInvitationEmail });
        } catch (error) {
          throw new Error(error);
        }
      })
    );

  return {
    isCreatingUsers: isCreatingUser,
    createUsers,
  };
};

export const useUpdateIdentity = () => {
  const identityManagementApi = useIdentityManagementApi();

  return useMutation<PersistedIdentityDetails, Error, AdminUpdateIdentityRequest>({
    mutationFn: ({ identityCode, identityDetails }) =>
      identityManagementApi.adminUpdateIdentity({ identityCode, identityDetails }),
  });
};
export const useGetIdentity = () => {
  const identityManagementApi = useIdentityManagementApi();

  return useMutation<PersistedIdentityDetails, Error, AdminGetIdentityDetailsRequest>({
    mutationFn: ({ identityCode }) =>
      identityManagementApi.adminGetIdentityDetails({ identityCode }),
  });
};
